export interface CommentairesEntry {
  revision: string;
  pneumatiques: string;
  reparation: string;
  carrosserie: string;
  controlesTechniques: string;
  sinistre: string;
  brisGlace: string;
  depannageRemorquage: string;
  vehiculeRemplacement: string;
  carteCarburantTelebadge: string;
  commandeLivraisonRestitution: string;
  amendes: string;
  commentaireParDefaut: string;
}

export enum ServiceCopilot {
  RELEVE_KILOMETRIQUE,
  TROUVER_PRESTATAIRE,
  ETAT_DES_LIEUX_VEHICULE,
  REPARATION_CARROSSERIE,
  TRANSMETTRE_DOCUMENT,
  DOCUMENTS_VEHICULE,
}

export enum Prestations {
  REVISION,
  REPARATION,
  CONTROLE_TECHNIQUE,
  PNEUMATIQUE,
  CARROSSERIE,
  VITRAGE,
}

export enum TypePrestations {
  OUI = 'OUI',
  NON = 'NON',
}

export interface PrestationsVehiculeCopilotEntry {
  prestationCopilot: Prestations;
  typePrestation: TypePrestations;
}

export interface Vehicule {
  id: string;
  numeroDossier: string;
  immatriculation: string;
  typeVehicule: string;
  marqueVehicule: string;
  conducteur: string;
  energie: string;
  modele: string;
  proprietaire: string;
  dateMEC: Date;
  version: string;
  commentaires: CommentairesEntry;
  dernierReleveKilometrique: number;
  releveKilometriqueSource: string;
  dateDernierReleveKilometrique: string;
  entiteClientId: string;
  dateFinContrat: Date;
  services: ServiceCopilot[];
  prestationsCopilot: PrestationsVehiculeCopilotEntry[];
  logoEntite: string;
  ptac: number;
  tauxCo2: number;
  critAir: string;
  alertes: Alerte[];
  dateDernierControleTechnique: Date;
  dateDerniereRevision: Date;
}
export interface InfosVehicule {
  idActif: string;
  numeroDossier: string;
  immatriculation: string;
  typeActif: string;
  marque: string;
  energie: string;
  modele: string;
  version: string;
  dateSortie: string | null; // Changé en string | null
  dernierReleveKilometrique: number;
  idCompte: string;
}

export interface Alerte {
  dateCible: Date; // Changé en string
  idActif: string;
  kilometrage: number; // Ajusté pour correspondre au type primitif et gérer null
  motifVisite: string;
}

export interface VehiculeSimplifie {
  infosVehicule: InfosVehicule;
  alertes: Alerte[];
}

export interface ReleveKilometrique {
  idActif: string;
  kilometrage: number;
  date: Date;
  source: string;
}
